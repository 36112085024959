import { CButton, CCol, CFormInput, CModal, CModalBody, CRow } from '@coreui/react'
import React, { useState } from 'react'
import AuthService from 'src/services/AuthService'
import TokenService from 'src/services/TokenService'
import swal from 'sweetalert'

const ChangePasswordModal = ({ visible, onClose }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const login = async () => {
    if (!password) {
      swal('Error!', 'Please Enter Password', 'error')
      return
    }

    if (!confirmPassword) {
      swal('Error!', 'Please Enter Password', 'error')
      return
    }

    if (confirmPassword != password) {
      swal('Error!', 'Password and Confirm Password must be same', 'error')
      return
    }

    const userDetails = TokenService.getTokenDetails()

    const data = {
      username: userDetails.username,
      level: userDetails.roles[0],
      password,
    }

    swal({
      title: "Warning!",
      text: 'Are you sure that you want to change the password?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Yes!'],
    }).then(async (willchange) => {
      if (willchange) {
        await AuthService.updateUser(userDetails.sub, data)
          .then(() => {
            swal('Success!', 'User Password Updated Successfully', 'success').then(() => logOut())
            onClose(false)
          })
          .catch((error) => {
            if (error.response.data.message) swal('Error!', error.response.data.message, 'error')
            else swal('Error!', 'Internal Server Error', 'error')
          })
      }
    })
  }

  const logOut = () => {
    AuthService.logout()
    window.location.href = '/'
    // change status
  }

  return (
    <CModal
      style={{
        marginTop: '40%',
        padding: '5%',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 18px 38px rgba(0,0,0,0.30), 0 150px 1000px rgba(0,0,0,0.22)',
      }}
      visible={visible}
      backdrop={false}
      onClose={() => onClose(false)}
      //style={{boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'}}
    >
      <CModalBody>
        <CRow>
          <CCol>
            <div>
              <div>
                <p className="fs-3" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Change Password
                </p>

                <CRow className="mt-4">
                  <CFormInput
                    id="was"
                    label="Password"
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') login()
                    }}
                    autoComplete="off"
                    aria-label="Default select example"
                  />
                </CRow>
                <CRow className="mt-4">
                  <CFormInput
                    id="username"
                    label="Confirm Password"
                    value={confirmPassword}
                    type={'password'}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    aria-label="Default select example"
                    autoComplete="off"
                  />
                </CRow>
                <div className="d-grid gap-5 d-md-flex justify-content-md-around mt-5">
                  <CButton
                    color="success"
                    style={{ color: '#fff', width: '100%', backgroundColor: '#00C4BA' }}
                    onClick={() => login()}
                  >
                    Change
                  </CButton>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  )
}

export default ChangePasswordModal
