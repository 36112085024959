const MAIN_API = process.env.REACT_APP_MAIN_API

export const MAIN_API_FOR_PHOTO = MAIN_API + 'public/'
export const FINGERPRINT_API = process.env.REACT_APP_FINGERPRINT_API
const redeploy = true

export const FINGERPRINT_STATUS = false
export const COMPANY_NAME = "COMPANY NAME"
export const IMAGE_FILE_PATH = ""
export const LAB_RESULT_ENTRY_RECURRENT = false
export const DEMO_SITE= true

export const PRINT_MARGINS = {
  // GHC_padding_top: '9mm' //this is okay for letter paper,
  // all are set to minimum margin
  GHC_padding_top: '5mm',
  GHC_REF_padding_top: '43mm',
  Normal_padding_top: '4mm',
  Normal_ref_padding_top: '43mm',
}

export const API = {
  LOGIN: MAIN_API + 'auth/login',
  MEDICAL_REGISTER: MAIN_API + 'medical-register',
  MEDICAL_TYPE: MAIN_API + 'medical-type',
  MEDICAL_ISSUES: MAIN_API + 'medical-issues',
  LAB_REMARK: MAIN_API + 'lab-remark',
  DOCTOR_REMARK: MAIN_API + 'doctor-remark',
  RESULT_ENTRY: MAIN_API + 'result-entries',
  COUNTRY: MAIN_API + 'country',
  AGENCY: MAIN_API + 'agency',
  DOCTOR: MAIN_API + 'doctor',
  TREATMENT: MAIN_API + 'medical-certificate',
  POSITION: MAIN_API + 'position',
  HONORIFIC: MAIN_API + 'honorific',
  CITY: MAIN_API + 'city',
  NATIONALITY: MAIN_API + 'nationality',
  DELIVERY: MAIN_API + 'delivery',
  REPORTS: MAIN_API + 'reports',
  MEDICAL_TEST_REPORTS: MAIN_API + 'reports/medical-test-reports',
  PHOTO: MAIN_API + 'gallery',
  PAYMENT: MAIN_API + 'payment',
  MMR_PAYMENT: MAIN_API + 'mmr-payment',
  USER_REGISTER: MAIN_API + 'user',
  FINGERPRINT: MAIN_API + 'finger-print',
  ENJAZ: MAIN_API + 'enjaz-details',
  LOGS: MAIN_API + 'activity-log',
}

export const FINFERPRINT_COMMON_MESSAGES = {
  FAIL_IDENTITY: 'FAILED IDENTIFYING  USER',
  IDENTIFY: 'IDENTIFYING USER',
  RESET_AGAIN: 'IDENTIFYING RESET FOR NEW',
  SUCCESS_IDENTIFYING: 'SUCCESS IDENTIFYING  USER',
  ENROLL_NEW_USER: 'ENROLLING NEW USER',
  ENROLL_RESET_AGAIN: 'ENROLLING RESET FOR NEW',
  ENROLL_SUCCESS_L: 'ENROLL SUCCESS ON LEFT THUMB',
  ENROLL_SUCCESS_R: 'ENROLL SUCCESS ON RIGHT THUMB',
}

export const LOGS = {
  ACTION_TYPES: {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
  },
}

export const honorifics = [
  'MR.',
  'MS.',
  'MRS.',
  'MISS.',
  'MASTER.',
  'BABY.',
  'SIS.',
  'DR.',
  'REV.',
  'MOULAVI',
]

export const user_status = [
  {
    label: 'MARRIED',
    value: 'MARRIED',
  },
  {
    label: 'SINGLE',
    value: 'SINGLE',
  },
  {
    label: 'WIDOWED',
    value: 'WIDOWED',
  },
]

export const places = [
  { value: 'AMPARA', label: 'Ampara' },
  { value: 'ANURADHAPURA', label: 'Anuradhapura' },
  { value: 'BADULLA', label: 'Badulla' },
  { value: 'BATTICALOA', label: 'Batticaloa' },
  { value: 'COLOMBO', label: 'Colombo' },
  { value: 'GALLE', label: 'Galle' },
  { value: 'GAMPAHA', label: 'Gampaha' },
  { value: 'HAMBANTOTA', label: 'Hambantota' },
  { value: 'JAFFNA', label: 'Jaffna' },
  { value: 'KANDY', label: 'Kandy' },
  { value: 'KEGALLE', label: 'Kegalle' },
  { value: 'KILINOCHCHI', label: 'Kilinochchi' },
  { value: 'KURUNEGALA', label: 'Kurunegala' },
  { value: 'MANNAR', label: 'Mannar' },
  { value: 'MATALE', label: 'Matale' },
  { value: 'MATARA', label: 'Matara' },
  { value: 'MONERAGALA', label: 'Moneragala' },
  { value: 'MULLAITIVU', label: 'Mullaitivu' },
  { value: 'NUWARA ELIYA', label: 'Nuwara Eliya' },
  { value: 'PLONNARUWA', label: 'Plonnaruwa' },
  { value: 'PUTTALAM', label: 'Puttalam' },
  { value: 'RATNAPURA', label: 'Ratnapura' },
  { value: 'TRINCOMALEE', label: 'Trincomalee' },
  { value: 'VAVUNIYA', label: 'Vavuniya' },
]

export const navigationPanel = [
  {
    name: 'Data Capture',
    items: [
      {
        name: 'Register',
        icon: 'person_add',
        href: '/#/register',
        isBookMarked: false,
        level: [4, 7, 8, 9, 10],
        show: true,
      },
      {
        name: 'Management Approval',
        icon: 'pending_actions',
        href: '/#/approval',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
      {
        name: 'Lab Remark',
        icon: 'lab_research',
        href: '/#/lab-remark',
        isBookMarked: false,
        level: [7, 9, 10],
        show: true,
      },
      {
        name: 'Medical Issues',
        icon: 'medical_information',
        href: '/#/medical-issues',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
      {
        name: 'PRO & Doctor',
        icon: 'stethoscope',
        href: '/#/pro-doctor-remark',
        isBookMarked: false,
        level: [1, 9, 10],
        show: true,
      },
      {
        name: 'Result Entry',
        icon: 'lab_profile',
        href: '/#/medical-result-entry',
        isBookMarked: false,
        level: [7, 8, 9, 10],
        show: true,
      },

      {
        name: 'Delivery',
        icon: 'local_shipping',
        href: '/#/delivery',
        isBookMarked: false,
        level: [3, 4, 9, 10],
        show: true,
      },
      {
        name: 'Payments',
        icon: 'credit_card',
        href: '/#/payments',
        isBookMarked: false,
        level: [4, 9, 10],
        show: true,
      },

      {
        name: 'Finger Verification',
        icon: 'fingerprint',
        href: '#/finger-verification',
        isBookMarked: false,
        level: [1, 2, 3, 4, 9, 10],
        show: true,
      },
      {
        name: 'Photo Capture',
        icon: 'camera',
        href: '/#/photo-capture',
        isBookMarked: false,
        level: [3, 9, 10],
        show: true,
      },
    ],
  },
  {
    name: 'Inquires',
    items: [
      {
        name: 'Passport Inquiry',
        icon: 'badge',
        href: '#/medical-inquires',
        isBookMarked: false,
        level: [1, 2, 3, 4, 7, 8, 9, 10],
        show: true,
      },
      {
        name: 'ENJAZ Inquiry',
        icon: 'badge',
        href: '#/enjaz-inquires',
        isBookMarked: false,
        level: [1, 2, 3, 4, 7, 8, 9, 10],
        show: true,
      },
    ],
  },
  {
    name: 'Master Files',
    items: [
      {
        name: 'Doctor Master Files',
        icon: 'stethoscope',
        href: '#/doctor-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Agency Master Files',
        icon: 'manage_accounts',
        href: '/#/agency-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Poistion Master Files',
        icon: 'manage_accounts',
        href: '/#/position-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Medical Master Files',
        icon: 'medical_information',
        href: '#/treatment-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
      {
        name: 'Country Master Files',
        icon: 'flag',
        href: '#/country-master',
        isBookMarked: false,
        level: [4, 8, 9, 10],
        show: true,
      },
    ],
  },
  {
    name: 'Reports',
    items: [
      {
        name: 'Lab Reports',
        icon: 'labs',
        href: '/#/lab-reports',
        isBookMarked: false,
        level: [7, 9, 10],
        show: true,
      },
      {
        name: 'Medical List with details',
        icon: 'admin_meds',
        href: '/#/medical-list-reports',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
      {
        name: 'Management Reports',
        icon: 'lab_profile',
        href: '/#/management-reports',
        isBookMarked: false,
        level: [8, 9, 10],
        show: true,
      },
    ],
  },
  {
    name: 'Setting Entry',
    items: [
      {
        name: 'User Accounts',
        icon: 'manage_accounts',
        href: '/#/user',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
      {
        name: 'Activity Logs',
        icon: 'logs',
        href: '/#/logs',
        isBookMarked: false,
        level: [9, 10],
        show: true,
      },
    ],
  },
]
