import { jwtDecode } from "jwt-decode";


const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user-cloud-care"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user-cloud-care"));
  return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user-cloud-care"));
  user.accessToken = token;
  localStorage.setItem("user-cloud-care", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user-cloud-care"));
};

const getUsername = () => {
  return JSON.parse(localStorage.getItem("user-cloud-care"))?.username;
};

const getUserLevel = () => {
  return JSON.parse(localStorage.getItem("user-cloud-care"))?.level;
};

const setUser = (user) => {
  localStorage.setItem("user-cloud-care", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user-cloud-care");
};

const getToken = () => {
  return JSON.parse(localStorage.getItem("user-cloud-care"))?.access_token;
}

const getTokenDetails = () => {
  if(JSON.parse(localStorage.getItem("user-cloud-care"))?.access_token) {
  const decodedToken = jwtDecode(JSON.parse(localStorage.getItem("user-cloud-care"))?.access_token);
  return decodedToken; 
  
}

return null
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getUsername,
  getToken,
  getUserLevel,
  getTokenDetails
};

export default TokenService;
