import React, { useEffect, useState } from 'react'
import {
  CButton,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
} from '@coreui/react/dist'
import { navigationPanel } from 'src/common/const'
import AuthService from 'src/services/AuthService'
import TokenService from 'src/services/TokenService'
import { navigationLevelChecker } from 'src/common/common'
import LOGO from '../assets/sample.png'
import ChangePasswordModal from './Modals/ChangePasswordModal'

function MainHeader() {
  const [navigationData, setNavigationData] = useState([])
  const [changePwModalVisible, setChangePwModalVisible] = useState(false)
  useEffect(() => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)

    if (!localStorage.getItem('navigation')) {
      localStorage.setItem('navigation', JSON.stringify(navigationPanel))
      navigationLevelChecker()
    }
    setNavigationData(JSON.parse(localStorage.getItem('navigation')))
    document.documentElement.style.zoom = `${Number(
      localStorage.getItem('care-cloud-zoom-level'),
    )}%`
    navigationLevelChecker()
  }, [])

  const [zoomLevel, setZoomLevel] = useState(100) // Initial zoom level

  const handleZoomIn = () => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)
    else {
      const newZoomLevel = Number(localStorage.getItem('care-cloud-zoom-level')) + 5
      localStorage.setItem('care-cloud-zoom-level', newZoomLevel)
      setZoomLevel(newZoomLevel)
      document.documentElement.style.zoom = `${newZoomLevel}%`
    }
  }

  const handleZoomOut = () => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)
    else {
      const newZoomLevel = Number(localStorage.getItem('care-cloud-zoom-level')) - 5
      localStorage.setItem('care-cloud-zoom-level', newZoomLevel)
      setZoomLevel(newZoomLevel)
      document.documentElement.style.zoom = `${newZoomLevel}%`
    }
    // const newZoomLevel = zoomLevel - 10
    // setZoomLevel(newZoomLevel)
    // document.documentElement.style.zoom = `${newZoomLevel}%`
  }

  const addToFav = (menu, item) => {
    const data = JSON.parse(localStorage.getItem('navigation'))

    const updatedMenu = menu.items.map((element) => {
      if (element.name == item.name) {
        element.isBookMarked = true
      }
      return element
    })
    const updatedData = data.map((element) => {
      if (element.name == menu.name) {
        element.items = updatedMenu
      }
      return element
    })
    localStorage.setItem('navigation', JSON.stringify(updatedData))
    window.location.reload(false)
  }

  const removeFromFav = (menu, item) => {
    const data = JSON.parse(localStorage.getItem('navigation'))

    const updatedMenu = menu.items.map((element) => {
      if (element.name == item.name) {
        element.isBookMarked = false
      }
      return element
    })
    const updatedData = data.map((element) => {
      if (element.name == menu.name) {
        element.items = updatedMenu
      }
      return element
    })
    localStorage.setItem('navigation', JSON.stringify(updatedData))
    window.location.reload(false)
  }

  const logOut = () => {
    AuthService.logout()
    window.location.href = '/'
    // change status
  }

  const resetSettings = () => {
    localStorage.removeItem('navigation')
    localStorage.removeItem('care-cloud-zoom-level')
    window.location.reload(false)
  }

  const filterDropDownMenu = (items) => {
    const array = items.filter((item) => item.show)
    return array.length
  }

  return (
    <>
      <div>
        <CContainer fluid>
          <div style={{ fontSize: '0.5em', display: 'flex', justifyContent: 'end' }}>
            {navigationData.map((menu, key) =>
              menu.items.map((item, index) => (
                <CNavLink
                  href={item.href}
                  hidden={!item.isBookMarked || !item.show}
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingTop: '1px',
                    paddingBottom: '3px',
                    paddingLeft: '30px',
                    cursor: 'pointer',
                    color: '#95BCDB',
                  }}
                >
                  <span style={{ fontSize: '3em' }} className="material-symbols-outlined">
                    {item.icon}
                  </span>
                  <br />
                  <span style={{ fontSize: '1.5em' }}>  {item.name}</span>
                </CNavLink>
              )),
            )}
          </div>
        </CContainer>
      </div>
      <CNavbar expand="sm" style={{ backgroundColor: '#FFF', boxShadow: '0px 3px 8px' }}>
        <CContainer fluid>
          <CNavbarNav  style={{ fontSize: '0.9em', color: '#FFF' }}>
            {navigationData.map(
              (menu, key) =>
                filterDropDownMenu(menu.items) != 0 && (
                  <CDropdown
                    variant="nav-item"
                    popper={false}
                    key={key}
                    className='dropdown-header'
                    style={{
                      backgroundColor: '#95BCDB',
                      margin: '10px',
                      borderRadius: '6px',
                      fontWeight: 'bold',

                    }}
                  >
                    <CDropdownToggle className='dropdown-header' color="primary" size="sm">
                      {menu.name}
                    </CDropdownToggle>
                    <CDropdownMenu  style={{ fontSize: '1.1em', minWidth: '250px' }}>
                      {menu.items.map((item, index) => (
                        <CDropdownItem key={index} href={item.href} hidden={!item.show}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{item.name} </span>
                            {!item.isBookMarked ? (
                              <span
                                style={{ fontSize: '1em', paddingTop: '3px' }}
                                onClick={() => addToFav(menu, item)}
                                className="material-symbols-outlined"
                              >
                                star
                              </span>
                            ) : (
                              <span
                                style={{ fontSize: '1em', paddingTop: '3px' }}
                                onClick={() => removeFromFav(menu, item)}
                                className="material-symbols-outlined"
                              >
                                do_not_disturb_on
                              </span>
                            )}
                          </div>
                        </CDropdownItem>
                      ))}
                      {/* <CDropdownDivider /> */}
                      {/* <CDropdownItem href="#">About</CDropdownItem> */}
                    </CDropdownMenu>
                  </CDropdown>
                ),
            )}
          </CNavbarNav>

          <div style={{ marginLeft: '200px' }}>
            <CButton
              onClick={handleZoomIn}
              variant="outline"
              color="light"
              style={{ color: '#95BCDB', border: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  gap: 3,
                }}
              >
                <span style={{ fontSize: '2em' }} className="material-symbols-outlined">
                  zoom_in
                </span>
                Zoom In
              </div>
            </CButton>
            <CButton
              onClick={handleZoomOut}
              variant="outline"
              color="light"
              style={{ color: '#95BCDB', border: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  gap: 3,
                }}
              >
                <span style={{ fontSize: '2em' }} className="material-symbols-outlined">
                  zoom_out
                </span>
                Zoom Out
              </div>
            </CButton>
          </div>
          <CDropdown
            dark
            variant="btn-group"
            direction="center"
            style={{
              textAlign: 'center',
              fontSize: '0.8em',
              padding: '10px',
              paddingLeft: '10px',
              cursor: 'pointer',
              textDecoration: 0,
            }}
          >
            <CButton color="light" style={{ backgroundColor: ' #95BCDB' }}>
              <div
               className='dropdown-header'
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 10,
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    color: '#fff',
                    padding: 0,
                    fontSize: '1.1em',
                    fontWeight: 'bolder',
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>
                <span
                  style={{
                    color: '#fff',
                    padding: 0,
                    fontSize: '1em',
                    fontWeight: 'bolder',
                  }}
                >
                  {TokenService.getUsername()}
                </span>
              </div>
            </CButton>
            <CDropdownToggle color="light" className='dropdown-header' style={{ backgroundColor: ' #95BCDB' }} split  />

            <CDropdownMenu>
              <CDropdownItem onClick={logOut}>Logout</CDropdownItem>
              <CDropdownItem onClick={resetSettings}>Reset Nav Bar</CDropdownItem>
              <CDropdownItem onClick={() => setChangePwModalVisible(true)}>Change Password</CDropdownItem>
              <CDropdownItem href='/#/settings'>Settings</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CContainer>
        <CNavbarBrand
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            color: '#fff',
            fontWeight: 'bolder',
            fontSize: '2em',
            fontStyle: 'italic',
          }}
          href="/"
        >
          <CImage src={LOGO} height={50} />
        </CNavbarBrand>
      </CNavbar>
      <ChangePasswordModal visible={changePwModalVisible} onClose={(status) => setChangePwModalVisible(status)} />
    </>
  )
}

export default MainHeader
